import { TitleStyled, useResponsiveBooleansContext } from 'backgammon_ui_shared';
import React from 'react';
import { BetaRibbon, Container, Icon, SmallIcon } from './CarouselItem.styled';
import { getStaticResource } from '../../../../../shared';
import { isDesktopScreen } from '../../../../../app.constants';

interface Props {
  title: string;
  iconPath?: string;
  disabled?: boolean;
  onClick?(): void;
  background?: string;
  isBeta?: boolean;
}

function CarouselItem({ title, iconPath, onClick, isBeta, disabled, background }: Props) {
  const { isSuperSmall, isTabOrScreen } = useResponsiveBooleansContext();

  return (
    <Container
      isDesktop={isDesktopScreen}
      disabled={disabled}
      onClick={onClick}
      isTabOrScreen={isTabOrScreen}
      isSuperSmall={isSuperSmall}
      background={background}
    >
      {isBeta ? <BetaRibbon>BETA</BetaRibbon> : null}
      {isDesktopScreen ? (
        <SmallIcon src={getStaticResource(iconPath)} />
      ) : (
        <Icon isSuperSmall={isSuperSmall} isTabOrScreen={isTabOrScreen} iconPath={getStaticResource(iconPath)} />
      )}
      <TitleStyled
        isCentered={true}
        marginTop={isTabOrScreen ? -30 : 0}
        isStretched={false}
        isInUpperCase={false}
        isSubHeading={true}
        color="text"
        fontSize={'large'}
      >
        {title}
      </TitleStyled>
    </Container>
  );
}

export default CarouselItem;
