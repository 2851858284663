import { bgColors } from 'backgammon_ui_shared';
import styled, { CSSObject } from 'styled-components';
import { size } from '../../../../styles/mixins/size';

export const Container = styled.button<{
  disabled?: boolean;
  isTabOrScreen: boolean;
  isSuperSmall: boolean;
  isDesktop: boolean;
  background?: string;
}>(
  ({ disabled, isTabOrScreen, isSuperSmall, isDesktop, background }): CSSObject => ({
    border: 0,
    background: background ?? bgColors.buttonDefault,
    borderRadius: '13px',
    position: 'relative',
    color: bgColors.textPrimary,
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: isDesktop ? '8px' : '16px',
    height: isTabOrScreen ? 370 : isSuperSmall ? 170 : 192,
    opacity: disabled ? 0.7 : 1,
    transition: 'filter 0.1s ease-in-out, transform 0.1s linear',
    width: isTabOrScreen ? 300 : 175,
    ...(!disabled && {
      '&:hover': { transform: 'scale(1.05)' },
      '&:active': { filter: 'brightness(0.8)' },
    }),

    ['@media(min-width: 1200px)']: {
      width: '175px',
      height: '50px',
      margin: 0,
      padding: 0,
      borderRadius: '5px',

      '& > h1': {
        fontSize: '1.2em',
        margin: 0,
      },
      '&::after': {
        content: 'BETA',
        position: 'fixed',
        width: '35px',
        height: '25px',
        background: bgColors.correct,
        top: '7px',
        left: '-20px',
        textAlign: 'center',
        fontSize: '13px',
        lineHeight: '27px',
        transform: 'rotate(-45deg)',
      },
    },
  }),
);

export const Icon = styled.span<{ iconPath: string; isSuperSmall: boolean; isTabOrScreen: boolean; isSVG?: boolean }>(
  ({ iconPath, isSuperSmall, isTabOrScreen, isSVG }): CSSObject => ({
    ...size(isTabOrScreen ? '300px' : isSuperSmall ? '135px' : '155px'),
    alignSelf: 'center',
    justifySelf: 'center',
    display: 'block',
    backgroundImage: `url("${iconPath}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 0%;',
    backgroundSize: isSVG ? 'contain' : 'cover',

    ['@media(min-width: 1200px)']: {
      display: 'none',
    },
  }),
);

export const SmallIcon = styled.img({
  width: '35px',
  height: '35px',
});

export const BetaRibbon = styled.div`
  --f: 5px; /* control the folded part*/
  --r: 10px; /* control the ribbon shape */
  --t: 0px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: ${bgColors.blunder};
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
`;
